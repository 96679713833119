$(window).scroll(function () {
    $('.tooltip__wrapper').toggleClass('tooltip__sticky', $(window).scrollTop() > 100);
    $('.header__products').toggleClass('header__products_show', $(window).scrollTop() > 300);
});

$(document).ready(function(){
    $('img').attr('oncontextmenu','return false;');

    var owlEye = $('.logo__owlEye');
    var startWinkAnimation = () => {

        owlEye.toggleClass('logo__owlEye_hide');

        setTimeout(() => {
            $('.logo__owlEye_close').addClass('logo__owlEye_open');
        }, 240);
        setTimeout(() => {
            owlEye.toggleClass('logo__owlEye_hide');
        }, 400);
        setTimeout(() => {
            $('.logo__owlEye_close').removeClass('logo__owlEye_open');
        }, 500);

    };
    setTimeout(startWinkAnimation, 3000);
    setInterval(startWinkAnimation, 10000);


    $('[data-select-variant]').each(function () {
        if ($(this).attr('style') === 'background: #ffffff;') {
            $(this).addClass('color_white');
        }
    });


    //Двухцветные
    $('[data-product-variant="22"]').append(twiceRedBlack());
    $('[data-product-variant="22"]').removeAttr('style');

    $('[data-product-variant="23"]').append(twicePinkFiolet());
    $('[data-product-variant="23"]').removeAttr('style');

    $('[data-product-variant="29"]').append(twiceBlueYellow());
    $('[data-product-variant="29"]').removeAttr('style');

    $('[data-product-variant="35"]').append(tiwceBlackRed());
    $('[data-product-variant="35"]').removeAttr('style');


    //Комбинированные
    $('[data-product-variant="27"]').append(combGreen());
    $('[data-product-variant="27"]').removeAttr('style');

    $('[data-product-variant="26"]').append(combPink());
    $('[data-product-variant="26"]').removeAttr('style');

    $('[data-product-variant="62"]').append(comboBlue());
    $('[data-product-variant="62"]').removeAttr('style');

    $('[data-product-variant="61"]').append(comboFiolet());
    $('[data-product-variant="61"]').removeAttr('style');

    $('[data-product-variant="63"]').append(comboYellow());
    $('[data-product-variant="63"]').removeAttr('style');
});

function changeMenuActivity(activity, open, close) {
    if (activity == 'show') {
        $(open).css('display', 'none');
        $(close).css('display', 'flex');
    } else if (activity == 'hide') {
        $(close).css('display', 'none');
        $(open).css('display', 'flex');
    }
}


$('.header__btn').on("click", function () {
    if ($(this).index() === 1 || $(this).index() === 2) {
        $('.header-menu').toggleClass('header-menu_show', !$('.header-menu').hasClass('header-menu_show'));

        let activity = $('.header-menu').hasClass('header-menu_show') ? 'show' : 'hide';
        changeMenuActivity(activity, $('.btn_open'), $('.btn_close'));
    }
});


$('.tooltip__exit').click(function () {
    $('.tooltip__wrapper').toggleClass('tooltip__active');
});



$('.counter__control_plus').click(function () {
    let input = $(this).parent().children('input[name="amount"]');
    $(input).val(parseInt($(input).val()) + 1);
});



$('.counter__control_minus').click(function () {
    let input = $(this).parent().children('input[name="amount"]');
    if ($(input).val() > 1) {
        $(input).val(parseInt($(input).val()) - 1);
    }
})



$('.counter__input').keyup(function () {
    $(this).val($(this).val().replace(/[^0-9]/g, ''));
});


function twiceRedBlack() {
    return `
    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="13" cy="13.3462" r="13" fill="#BA0C2F"></circle>
							<path d="M13 0.346191C16.4478 0.346191 19.7544 1.71583 22.1924 4.1538C24.6304 6.59177 26 9.89838 26 13.3462C26 16.794 24.6304 20.1006 22.1924 22.5386C19.7544 24.9766 16.4478 26.3462 13 26.3462L13 13.3462L13 0.346191Z" fill="#2D2926"></path>
							</svg>
    `
}


function twicePinkFiolet() {
    return `
    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="13" cy="13.3462" r="13" fill="#5C068C"></circle>
							<path d="M13 0.346191C16.4478 0.346191 19.7544 1.71583 22.1924 4.1538C24.6304 6.59177 26 9.89838 26 13.3462C26 16.794 24.6304 20.1006 22.1924 22.5386C19.7544 24.9766 16.4478 26.3462 13 26.3462L13 13.3462L13 0.346191Z" fill="#FF5FA2"></path>
							</svg>
    `
}


function twiceBlueYellow() {
    return `
    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="13" cy="13.3462" r="13" fill="#303AB2"></circle><path d="M13 0.346191C16.4478 0.346191 19.7544 1.71583 22.1924 4.1538C24.6304 6.59177 26 9.89838 26 13.3462C26 16.794 24.6304 20.1006 22.1924 22.5386C19.7544 24.9766 16.4478 26.3462 13 26.3462L13 13.3462L13 0.346191Z" fill="#FFC72C"></path></svg>
    `
}


function tiwceBlackRed() {
    return `
    <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
							<circle cx="13" cy="13.3462" r="13" fill="#2D2926"></circle>
							<path d="M13 0.346191C16.4478 0.346191 19.7544 1.71583 22.1924 4.1538C24.6304 6.59177 26 9.89838 26 13.3462C26 16.794 24.6304 20.1006 22.1924 22.5386C19.7544 24.9766 16.4478 26.3462 13 26.3462L13 13.3462L13 0.346191Z" fill="#BA0C2F"></path>
							</svg>
    `
}


function combGreen() {
    return `
    <svg width="26" height="27" viewBox="12 11 26 27" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25.0279" cy="24.3343" r="13" transform="rotate(30 25.0279 24.3343)" fill="#2BD2CA"></circle><path d="M36.2862 30.8342C35.1452 32.8104 33.5041 34.4515 31.5279 35.5925C29.5517 36.7335 27.31 37.3342 25.028 37.3342C22.746 37.3342 20.5043 36.7336 18.528 35.5926C16.5518 34.4517 14.9107 32.8106 13.7697 30.8344L25.0279 24.3342L36.2862 30.8342Z" fill="#FFF48E"></path><path d="M13.8061 30.8972C12.654 28.9275 12.0407 26.6891 12.0278 24.4072C12.0149 22.1253 12.6029 19.8802 13.7327 17.8975C14.8624 15.9149 16.4942 14.2646 18.4639 13.1124C20.4337 11.9603 22.672 11.3469 24.9539 11.334L25.0276 24.3337L13.8061 30.8972Z" fill="#D5A5CB"></path></svg>`
}


function combPink() {
    return `
    <svg width="26" height="27" viewBox="12 11 26 27" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25.0005" cy="24.6165" r="13" transform="rotate(30 25.0005 24.6165)" fill="#FBC80B"></circle><path d="M36.2588 31.1164C35.1179 33.0927 33.4768 34.7337 31.5006 35.8747C29.5244 37.0157 27.2826 37.6164 25.0007 37.6164C22.7187 37.6164 20.4769 37.0158 18.5007 35.8749C16.5244 34.7339 14.8833 33.0929 13.7423 31.1167L25.0005 24.6164L36.2588 31.1164Z" fill="#2B2A28"></path><path d="M13.7788 31.1794C12.6267 29.2097 12.0133 26.9714 12.0004 24.6895C11.9875 22.4075 12.5755 20.1624 13.7053 18.1798C14.8351 16.1971 16.4668 14.5468 18.4366 13.3946C20.4063 12.2425 22.6446 11.6291 24.9265 11.6162L25.0002 24.616L13.7788 31.1794Z" fill="#42A93E"></path></svg>`
}

function comboBlue(){
    return `
    <svg width="26" height="27" viewBox="12 11 26 27" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25.0005" cy="24.6158" r="13" transform="rotate(30 25.0005 24.6158)" fill="#00A3E0"/><path d="M36.2588 31.1167C35.1179 33.0929 33.4768 34.734 31.5006 35.875C29.5244 37.016 27.2826 37.6166 25.0007 37.6167C22.7187 37.6167 20.4769 37.016 18.5007 35.8751C16.5244 34.7342 14.8833 33.0931 13.7423 31.1169L25.0005 24.6167L36.2588 31.1167Z" fill="#2B2A28"/><path d="M13.7788 31.1794C12.6267 29.2097 12.0133 26.9714 12.0004 24.6895C11.9875 22.4075 12.5755 20.1624 13.7053 18.1798C14.8351 16.1971 16.4668 14.5468 18.4366 13.3946C20.4063 12.2425 22.6446 11.6291 24.9265 11.6162L25.0002 24.616L13.7788 31.1794Z" fill="#303AB2"/></svg>` 
}

function comboYellow(){
    return `<svg width="26" height="27" viewBox="12 11 26 27" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="24.2222" cy="24.2212" r="13" transform="rotate(30 24.2222 24.2212)" fill="#42A93E"/><path d="M35.4805 30.7221C34.3395 32.6984 32.6985 34.3394 30.7223 35.4804C28.746 36.6214 26.5043 37.2221 24.2223 37.2221C21.9404 37.2222 19.6986 36.6215 17.7224 35.4806C15.7461 34.3396 14.105 32.6986 12.964 30.7224L24.2222 24.2221L35.4805 30.7221Z" fill="#F4F5F0"/><path d="M13.0005 30.7849C11.8483 28.8152 11.235 26.5768 11.2221 24.2949C11.2092 22.013 11.7972 19.7679 12.927 17.7852C14.0568 15.8026 15.6885 14.1522 17.6583 13.0001C19.628 11.8479 21.8663 11.2346 24.1482 11.2216L24.2219 24.2214L13.0005 30.7849Z" fill="#FDE100"/></svg>`;
}

function comboFiolet(){
    return `<svg width="26" height="27" viewBox="12 11 26 27" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="24.2222" cy="24.2212" r="13" transform="rotate(30 24.2222 24.2212)" fill="#FF5FA2"/><path d="M35.4805 30.7221C34.3395 32.6984 32.6985 34.3394 30.7223 35.4804C28.746 36.6214 26.5043 37.2221 24.2223 37.2221C21.9404 37.2222 19.6986 36.6215 17.7224 35.4806C15.7461 34.3396 14.105 32.6986 12.964 30.7224L24.2222 24.2221L35.4805 30.7221Z" fill="#5C068C"/><path d="M13.0005 30.7849C11.8483 28.8152 11.235 26.5768 11.2221 24.2949C11.2092 22.013 11.7972 19.7679 12.927 17.7852C14.0568 15.8026 15.6885 14.1522 17.6583 13.0001C19.628 11.8479 21.8663 11.2346 24.1482 11.2216L24.2219 24.2214L13.0005 30.7849Z" fill="#F4F5F0"/></svg>`;
}